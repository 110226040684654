<template>
  <!-- <div> -->
  <v-row class="mx-2">

    <v-col cols="12">
      <v-card flat style="border-radius: 12px" elevation="10">
        <v-card  class="elevation-0 ma-2">
          <v-row>
            <v-col class="d-flex align-center justify-start "
            ><span style="font-weight: bold; font-size:20px; margin-left:20px; " class="d-flex align-left">{{ $t("billings") }}</span>
            </v-col>
<!--            We are going to render the CPT codes here. all of them-->
            <v-col md="2" sm="2" xl="3">
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search')"
                  single-line
                  hide-details
                  dense
                  outlined
                  class="table-search-button"
                  style="border-radius:10px !important;"
              />
            </v-col>
            <v-col class="d-flex align-end justify-end" md="2" sm="2" xl="1">
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="-90"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date"
                      label="Filter date"
                      readonly
                      v-bind="attrs"
                      single-line
                      hide-details
                      prepend-inner-icon="mdi-calendar"
                      dense
                      v-on="on"
                      outlined
                      style="border-radius:10px !important;"
                      @input="updateDate"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" color="primary" type="month" no-title scrollable @change="menu1 = false"></v-date-picker>
              </v-menu>
            </v-col>
            <div v-if="this.roleName === 'HOSPITAL_ADMIN'|| this.roleName === 'DOCTOR' || this.roleName === 'NURSE'">
              <v-tooltip top>
                <template v-slot:activator="{on}">
                  <v-btn class="primary" @click="billingDataForHospitalAdmin()" style="margin-top:13px; margin-right:20px;" v-on="on">
                    <img class="my-1" height="17px" width="20px"  :src="invoice"/>
                  </v-btn>
                </template>
                <span>Generate billings</span>
              </v-tooltip>
            </div>
            <div v-if="this.roleName === 'ORGANIZATION_ADMIN'">
              <v-tooltip top>
                <template v-slot:activator="{on}">
                  <v-btn class="primary" @click="billingDataForOrganizationalAdmin" style="margin-top:13px; margin-right:20px;" v-on="on">
                    <img class="my-1" height="17px" width="20px"  :src="invoice"/>
                  </v-btn>
                </template>
                <span>{{$t('generate-billings')}}</span>
              </v-tooltip>
            </div>

<!--            <div v-if="roleName === 'SYSTEM_ADMIN'" link dense @click="generateCSV(item)">-->
<!--              -->
<!--            </div>-->
            <v-tooltip top>
              <template v-slot:activator="{on}">
                <v-btn class="primary" style="margin-top:13px; margin-right:20px;" @click="generateCSV()" v-on="on">
                  <span class="mdi mdi-cloud-download mdi-18px mt-1"></span>
                </v-btn>
              </template>
              <span>{{$t('download-csv')}}</span>
            </v-tooltip>

            <v-tooltip top v-if="this.roleName==='ORGANIZATION_ADMIN'">
              <template v-slot:activator="{ on }">
                <v-btn @click="generatePdfForAllHospitals" class="primary" v-on="on" style="margin-top:13px; margin-right:20px;">
                  <v-icon>mdi-elevation-rise</v-icon>
                </v-btn>
              </template>
              <span>{{$t('download-pdf')}}</span>
            </v-tooltip>

            <v-col cols="12">
            <template>
              <div style="display:flex; align-items:center; justify-content: start; ">
                <h6 style="margin-right:20px; margin-left:20px;">CPT</h6>
                <div class="text-center">
                  <v-progress-circular
                    class="px-3 mx-2"
                    :rotate="-90"
                    :size="80"
                    :width="4"
                    :value="billingSummary.cpt99453"
                    :color=checkColor(billingSummary.cpt99453)
                  >
                      99453 {{ billingSummary.cpt99453 }}%
                  </v-progress-circular>
                  <v-progress-circular
                    class="px-3 mx-2"
                    :rotate="-90"
                    :size="80"
                    :width="4"
                    :value="billingSummary.cpt99454"
                    :color=checkColor(billingSummary.cpt99454)>
                    99454 {{ billingSummary.cpt99454 }}%
                  </v-progress-circular>
                  <v-progress-circular
                    class="px-3 mx-2"
                    :rotate="-90"
                    :size="80"
                    :width="4"
                    :value="billingSummary.cpt99457"
                    :color=checkColor(billingSummary.cpt99457)>
                    99457 {{ billingSummary.cpt99457 }}%
                  </v-progress-circular>
                  <v-progress-circular
                    class="px-3 mx-2"
                    :rotate="-90"
                    :size="80"
                    :width="4"
                    :value="billingSummary.cpt994581st"
                    :color=checkColor(billingSummary.cpt994581st)>
                    99458 1st  {{ billingSummary.cpt994581st }}%
                  </v-progress-circular>
                  <v-progress-circular
                    class="px-3 mx-2"
                    :rotate="-90"
                    :size="80"
                    :width="4"
                    :value="billingSummary.cpt994582nd"
                    :color=checkColor(billingSummary.cpt994582nd)>
                    99458 2nd {{ billingSummary.cpt994582nd }}%
                  </v-progress-circular>

                </div>
              </div>
            </template>
            </v-col>
<!--            Render the cpt codes here..-->
          </v-row>
        </v-card>
        <!-- <v-card-text> -->

        <v-data-table
            :loading="loading"
            :headers="headers"
            :no-data-text="$t('no-data-available')"
            :loading-text="$t('loading')"
            :items="searchResults"
            :items-per-page="-1"
            :headers-length="headers.length"
            item-key="patientId"
            fixed-header
            dense
            style="cursor: pointer; margin-top:20px;"
            :height="calculateHeight"
            group-by="hospitalData.hospitalName"
            :expanded.sync="expanded"
            :single-expand="true"
            :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
        >

          <template  v-slot:[`group.header`]="{ items, toggle, group, isOpen}">
            <td @click="toggle" class="text-left elevation-3" :colspan="8" style="background-color:white;">
            <v-row class="py-2">
                <v-col cols="2" class="mt-1">
                  <span v-if="items && items.length > 0" style="font-weight: bold;">
                  {{items[0]?.hospitalData?.hospitalName}}
                  </span>
                </v-col>
              <div class="text-center mx-2 mt-3"  style="width:150px;">
                <v-progress-linear v-if="getHospitalSummary(items[0]?.hospitalData?.id)"
                    class="px-3 mx-2"
                    :height="28"
                    rounded
                    :value="getHospitalSummary(items[0]?.hospitalData?.id).percent99453"
                    color="primary"
                    style="color:white;"
                >
                  99453 {{ getHospitalSummary(items[0]?.hospitalData?.id).percent99453 }}%
                </v-progress-linear>
              </div>
              <div class="text-center mx-2 mt-3" style="width:150px; margin-top:14px;">
                <v-progress-linear v-if="getHospitalSummary(items[0]?.hospitalData?.id)"
                    class="px-3 mx-2"
                    :height="28"
                    rounded
                    :value="getHospitalSummary(items[0]?.hospitalData?.id).percent99454"
                    color="primary"
                    style="color:white;">
                  99454 {{ getHospitalSummary(items[0]?.hospitalData?.id).percent99454 }}%
                </v-progress-linear>
              </div>
              <div class="text-center mx-2 mt-3"  style="width:150px;">
                <v-progress-linear v-if="getHospitalSummary(items[0]?.hospitalData?.id)"
                    class="px-3 mx-2"
                    :height="28"
                    rounded
                    :value="getHospitalSummary(items[0]?.hospitalData?.id).percent99457"
                    color="primary"
                    style="color:white;">
                  99457 {{ getHospitalSummary(items[0]?.hospitalData?.id).percent99457 }}%
                </v-progress-linear>
                </div>
              <div class="text-center mx-2 mt-3"  style="width:150px;">
                <v-progress-linear v-if="getHospitalSummary(items[0]?.hospitalData?.id)"
                    class="px-3 mx-2"
                    :height="28"
                    rounded
                    :value="getHospitalSummary(items[0]?.hospitalData?.id).percent994581st"
                    color="primary"
                    style="color:white;">
                  99458 1st  {{ getHospitalSummary(items[0]?.hospitalData?.id).percent994581st }}%
                </v-progress-linear>
              </div>
              <div class="text-center mx-2 mt-3"  style="width:150px;">
                <v-progress-linear v-if="getHospitalSummary(items[0]?.hospitalData?.id)"
                    class="px-3 mx-2"
                    :height="28"
                    rounded
                    :value="getHospitalSummary(items[0]?.hospitalData?.id).percent994582nd"
                    color="primary"
                  style="color:white;"
                >
                  99458 2nd {{ getHospitalSummary(items[0]?.hospitalData?.id).percent994582nd }}%
                </v-progress-linear>

              </div>

                <v-col class="justify-end d-flex">
                  <div class="mx-2">
                    <v-tooltip top v-if="roleName==='ORGANIZATION_ADMIN'">
                      <template v-slot:activator="{on}">
                        <v-btn v-on="on" @click.stop="GeneratePdfFileForHospital(items[0]?.hospitalData?.id)" small icon style="border-radius: 6px; background-color: #6600FF;">
                          <v-icon color="white">mdi-elevation-rise</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('download-pdf')}}</span>
                    </v-tooltip>
                  </div>
                  <v-tooltip top>
                    <template v-slot:activator="{on}">
                      <v-btn  @click.stop="billingDataForHospitalAdmin(items[0]?.hospitalData?.id) " small icon  style=" border-radius: 6px; background-color: #6600FF ;" v-on="on">
                        <img  height="20px" width="15px" :src="invoice"/>
                      </v-btn>
                    </template>
                    <span>{{$t('generate-billings')}}</span>
                  </v-tooltip>

                    <span>
                      <v-btn  small icon :ref="group" :data-open="isOpen">
                        <v-icon v-if="isOpen" color="primary">mdi-arrow-up-drop-circle</v-icon>
                        <v-icon v-else color="primary">mdi-arrow-down-drop-circle</v-icon>
                      </v-btn>
                    </span>
                </v-col>
              </v-row>
            </td>
          </template>
          <template v-slot:item="{ item, group, isOpen }">
              <tr @click="expand(item)">
                <td style="padding:0px !important;">
                  <div class="d-flex font-weight-bold ml-4">
                    <span class="mr-1" style="font-size:15px;">{{item.patientData?.firstName}}</span>
                    <span style="font-size:15px;">{{item.patientData?.lastName}}</span>
                  </div>
                </td>

                <td style="padding:0px !important;">
                  <div style="width:150px;" class="my-2 mx-3">
                    <v-progress-linear
                        rounded
                        :height="28"
                        :value="item.cptCode99453?.status === 1 ? 100 : 0"
                        color="primary"
                        style="color:white;"
                    >
                      {{
                        item.cptCode99453?.status === 1
                            ? '99453 billable'
                            : '99453 not billable'
                      }}
                    </v-progress-linear>
                  </div>
                </td>
                <td style="padding:0px !important;">
                  <div style="width:150px;" class="my-2 mx-3">
                    <v-progress-linear
                        rounded
                        :height="28"
                        :value="item.myBillings?.percent99454"
                        color="primary"
                        style="color:white;"
                    >
                      {{
                        item.myBillings?.percent99454 === 100
                            ? '99454 billable'
                            : '99454 not billable'
                      }}
                    </v-progress-linear>
                  </div>
                </td>
                <td style="padding:0px !important;">
                  <div style="margin-right:10px;" class="my-2 mx-3">
                    <div style="width:150px;">
                      <v-progress-linear
                          rounded
                          :height="28"
                          :value="item.myBillings?.percent99457"
                          color="primary"
                          style="color:white;"
                      >
                        99457 {{ secToMinV2(item.myBillings?.seconds99457) }}
                      </v-progress-linear>
                    </div>
                  </div>
                    </td>
                <td style="padding:0px !important;">
                  <div style="width:150px;" class="my-2 mx-2">
                    <v-progress-linear
                        rounded
                        :height="28"
                        :value="item.myBillings?.percent994581st"
                        color="primary"
                        style="color:white;"
                    >
                      99458 1st {{ secToMinV2(item.myBillings?.seconds994581st) }}
                    </v-progress-linear>
                  </div>
                </td>
                <td style="padding:0px !important;">
                  <div style="width:150px;" class="my-2 mx-2">
                    <v-progress-linear
                        rounded
                        :height="28"
                        :value="item.myBillings?.percent994582nd"
                        color="primary"
                        style="color:white;"
                    >
                      99458 2nd {{ secToMinV2(item.myBillings?.seconds994582nd) }}
                    </v-progress-linear>
                  </div>
                </td>

                      <td class="col-right-alignment">
                        <v-btn  @click.stop="patientDataBilling(item.patientId) " small icon style=" border-radius: 6px; background-color: #6600FF ;">
                          <img  height="20px" width="15px" :src="invoice"/>
                        </v-btn>
                <v-btn small icon :ref="group" :data-open="isOpen">
                  <v-icon v-if="isOpen" color="primary">mdi-arrow-up-drop-circle</v-icon>
                  <v-icon v-else color="primary">mdi-arrow-down-drop-circle</v-icon>
                </v-btn>
              </td>
              </tr>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td colspan="8">
              <DetailsCard v-if="expanded" :item="item"></DetailsCard>
            </td>
          </template>
        </v-data-table>
        <!-- </v-card-text> -->
      </v-card>
    </v-col>
  </v-row>
  <!-- </div> -->
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import DetailsCard from '../components/BillingDetailsCard.vue';
import { billWhite } from '@/assets';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';

export default {
	components: {
		DetailsCard,
	},
	data () {
		return {
			loading: false,
			search: '',
			selectedChip: null,
			expanded: [],
			// itemTest: {
			// 	hospitalId: this.authUserData.hospitalAssignedData.id,
			// 	month: 11,
			// 	year: 2023,
			// },
			// billings: [],
			date: new Date().toISOString().substr(0, 7),
			menu1: false,
			invoice: billWhite,
			billingSummary: {
				cpt99453: 0,
				cpt99454: 0,
				cpt99457: 0,
				cpt994581st: 0,
				cpt994582nd: 0,
			},
			hospitalSummary: {
				cpt99453: 0,
				cpt99454: 0,
				cpt99457: 0,
				cpt994581st: 0,
				cpt994582nd: 0,
			},
			hospitalData: [],
		};
	},
	computed: {
		...mapState({
			billingsFromRepo: state => state.billings.billingSummaryForMonth,
			loggedUser: (state) => state.authentication.userData,
			authUserData: (state) => state.authentication.userData,
			generatedCsvFile: (state) => state.billings.generatedCsvFile,
			generatedPdfFile: (state) => state.billings.generatedPdfFile,
			generatePDF: (state) => state.billings.generatePDF,
		}),
		...mapGetters({
			roleName: 'authentication/getRole',
			hospitalId: 'authentication/getHospitalId',
		}),
		// searchResults () {
		// 	const searchTerm = this.search ? this.search.toLowerCase() : '';
		// 	return this.billingsFromRepo.filter(item => {
		// 		const firstName = item?.patientData && item.patientData.firstName ? item.patientData.firstName.toLowerCase() : '';
		// 		const lastName = item?.patientData && item.patientData.lastName ? item.patientData.lastName.toLowerCase() : '';
		// 		const healthInsuranceProgramData = item.healthInsuranceProgramData ? item.healthInsuranceProgramData.toLowerCase() : '';
		//
		// 		return firstName.includes(searchTerm) || lastName.includes(searchTerm) || healthInsuranceProgramData.includes(searchTerm);
		// 	});
		// },
		searchResults () {
			const searchTerm = this.search ? this.search.toLowerCase() : '';
			return this.billingsFromRepo.filter(item => {
				const patientData = item?.patientData;
				if (!patientData) {
					return false; // Skip items with null or undefined patientData
				}
				const firstName = item?.patientData && item.patientData.firstName ? item.patientData.firstName.toLowerCase() : '';
				const lastName = item?.patientData && item.patientData.lastName ? item.patientData.lastName.toLowerCase() : '';
				const healthInsuranceProgramData = item.healthInsuranceProgramData ? item.healthInsuranceProgramData.toLowerCase() : '';

				return firstName.includes(searchTerm) || lastName.includes(searchTerm) || healthInsuranceProgramData.includes(searchTerm);
			});
		},
		calculateHeight () {
			const windowHeight = window.innerHeight;
			const breakpointPercentage = {
				xs: 30,
				sm: 80.5,
				md: 70,
				lg: 68.8,
				xl: 69.0,
			};
			const breakpointName = this.$vuetify.breakpoint.name;
			const percentage = breakpointPercentage[breakpointName];

			if (percentage !== undefined) {
				const calculatedHeight = (windowHeight * percentage) / 100;
				return `${calculatedHeight}px`;
			} else {
				return '80%'; // Default percentage value
			}
		},
		headers () {
			return [
				{ text: 'Patient Name', value: 'patientName', sortable: true },
				{ text: '', value: 'Insurance', sortable: false },
				// { text: 'CptCode99454', value: 'cptCode99454', sortable: false },
				// { text: 'CptCode99457', value: 'cptCode99457', sortable: false },
				// { text: 'CptCode994581st', value: 'cptCode994581st', sortable: false },
				// { text: 'CptCode994582nd', value: 'cptCode994582nd', sortable: false },
			];
		},
	},
	watch: {
		date (val) {
			const month = val.slice(-2);
			this.initialize({
				month: month.charAt(0) === '0' ? month.charAt(1) : parseInt(month),
				year: parseInt(val.substring(0, 4)),
			});
		},
	},
	async mounted () {
		await this.initialize({
			month: this.date.substring(5, 7),
			year: parseInt(this.date.substring(0, 4)),
		});
	},
	methods: {
		async GeneratePdfFileForHospital (id) {
			const clientId = this.authUserData.organizationAssignedData.id;
			const dateParts = this.date.split('-');
			const year = parseInt(dateParts[0], 10);
			const month = parseInt(dateParts[1], 10);
			var officeIds = [];

			officeIds.push(id);

			const selectedYear = year;
			const selectedMonth = month;
			var clientName = 'Client';

			if (this.roleName !== 'ORGANIZATION_ADMIN') {
				clientName = this.authUserData.hospitalAssignedData.hospitalName;
			} else {
				clientName = this.authUserData.organizationAssignedData.institutionName;
			}

			const organizationData = { organizationId: clientId, month: selectedMonth, year: selectedYear, officeIds };
			await this.$store.dispatch('billings/generatedPdfFile', organizationData)
				.then(res => {
					showSuccessAlert(this.$t('pdf-downloaded'));
					const url = window.URL.createObjectURL(new Blob([this.generatedPdfFile.data]));
					const link = document.createElement('a');
					const csvFileName = `cpt-billings-reports-for-${clientName}-${selectedYear}-${selectedMonth}.pdf`;
					link.href = url;
					link.setAttribute('download', csvFileName);
					document.body.appendChild(link);
					link.click();
				});
		},
		async generatePdfForAllHospitals () {
			const clientId = this.authUserData.organizationAssignedData.id;
			const dateParts = this.date.split('-');
			const year = parseInt(dateParts[0], 10);
			const month = parseInt(dateParts[1], 10);
			const selectedYear = year;
			const selectedMonth = month;
			var clientName = 'Client';
			if (this.roleName !== 'ORGANIZATION_ADMIN') {
				clientName = this.authUserData.hospitalAssignedData.hospitalName;
			} else {
				clientName = this.authUserData.organizationAssignedData.institutionName;
			}
			const organizationData = { organizationId: clientId, month: selectedMonth, year: selectedYear };
			await this.$store.dispatch('billings/generatePdfForAllHospitals', organizationData)
				.then(res => {
					showSuccessAlert(this.$t('pdf-downloaded'));
					const url = window.URL.createObjectURL(new Blob([this.generatePDF.data]));
					const link = document.createElement('a');
					const csvFileName = `cpt-billings-reports-for-${clientName}-${selectedYear}-${selectedMonth}.pdf`;
					link.href = url;
					link.setAttribute('download', csvFileName);
					document.body.appendChild(link);
					link.click();
				});
		},
		updateDate () {
			this.$emit('date-selected', this.date);
		},
		getHospitalSummary (hospitalId) {
			// Check if the ID exists in the aggregatedData
			if (hospitalId in this.hospitalData) {
				// Return the aggregated data for the specified ID
				return this.hospitalData[hospitalId];
			} else {
				// Return null or handle the case when the ID is not found
				return null;
			}
		},
		async getMedicalInstitutions () {
			this.loading = true;
			await this.$store
				.dispatch('medicalInstitutions/getMedicalInstitutions')
				.then(() => {
					// this.medicalInstitutions = this.medicalInstitutionsFromRepo;
					this.loading = false;
				});
		},
		async generateCSV () {
			const dateParts = this.date.split('-');
			const year = parseInt(dateParts[0], 10);
			const month = parseInt(dateParts[1], 10);
			const currentYear = new Date().getFullYear();
			const currentMonth = new Date().getMonth() + 1;

			const selectedYear = year;
			const selectedMonth = month;
			var clientName = 'Client';
			var clientId = 0;
			if (this.roleName !== 'ORGANIZATION_ADMIN') {
				clientName = this.authUserData.hospitalAssignedData.hospitalName;
				clientId = this.authUserData.hospitalAssignedData.id;
			} else {
				clientName = this.authUserData.organizationAssignedData.institutionName;
				clientId = this.authUserData.organizationAssignedData.id;
			}

			if (selectedYear > currentYear || (selectedYear === currentYear && selectedMonth > currentMonth)) {
				showErrorAlert(this.$t('cant-download-csv-for-upcoming-months'));
				return;
			}

			if (this.roleName !== 'ORGANIZATION_ADMIN') {
				const hospitalData = { hospitalId: clientId, month: selectedMonth, year: selectedYear };
				await this.$store.dispatch('billings/generateCsvHospitalPatientsForMonth', hospitalData)
					.then(res => {
						showSuccessAlert(this.$t('csv-downloaded'));
						const url = window.URL.createObjectURL(new Blob([this.generatedCsvFile.data]));
						const link = document.createElement('a');
						const csvFileName = `cpt-billings-summary-for-${clientName}-${selectedYear}-${selectedMonth}-csv.csv`;
						link.href = url;
						link.setAttribute('download', csvFileName);
						document.body.appendChild(link);
						link.click();
					});
			} else {
				const organizationData = { organizationId: clientId, month: selectedMonth, year: selectedYear };
				await this.$store.dispatch('billings/generateCsvOrganizationPatientsForMonth', organizationData)
					.then(res => {
						showSuccessAlert(this.$t('csv-downloaded'));
						const url = window.URL.createObjectURL(new Blob([this.generatedCsvFile.data]));
						const link = document.createElement('a');
						const csvFileName = `cpt-billings-summary-for-${clientName}-${selectedYear}-${selectedMonth}-csv.csv`;
						link.href = url;
						link.setAttribute('download', csvFileName);
						document.body.appendChild(link);
						link.click();
					});
			}
		},
		checkColor (value) {
			if (value >= 0 && value <= 25) {
				return 'red';
			}
			if (value > 25 && value <= 50) {
				return 'orange';
			}
			if (value > 50 && value <= 75) {
				return 'blue';
			}
			if (value > 75 && value <= 95) {
				return 'teal';
			}
			if (value > 95 && value <= 100) {
				return 'green';
			}
		},

		secToMin (seconds) {
			var convert = function (x) {
				return (x < 10) ? '0' + x : x;
			};
			return convert(parseInt(seconds / (60 * 60))) + ' h ' +
          convert(parseInt(seconds / 60 % 60)) + ' min ' +
          convert(seconds % 60) + ' sec';
		},
		secToMinV2 (seconds) {
			const hours = Math.floor(seconds / 3600);
			const minutes = Math.floor((seconds % 3600) / 60);
			const remainingSeconds = seconds % 60;

			return `${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${remainingSeconds}s`;
		},
		closeAll () {
			Object.keys(this.$refs).forEach(k => {
				if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
					this.$refs[k].$el.click();
				}
			});
		},
		patientDataBilling (id) {
			const dataForPatientBilling = {
				patientid: id,
				month: this.date.substring(5, 7),
				year: this.date.substring(0, 4),
			};
			this.$store.dispatch('billings/generateforpatientformonth', dataForPatientBilling);
		},
		async billingDataForHospitalAdmin (id) {
			var hospitalId = null;
			if (id === undefined || id === null) {
				hospitalId = this.authUserData.hospitalAssignedData.id;
			} else {
				hospitalId = id;
			}
			const dataForBilling = {
				hospitalId: hospitalId, // id,
				month: this.date.substring(5, 7),
				year: this.date.substring(0, 4),
			};
			await this.$store.dispatch('billings/generateforallhospitalpatientsformonth', dataForBilling);
			await this.initialize(dataForBilling);
		},
		async billingDataForOrganizationalAdmin () {
			const dataForBillingOrganizationAdmin = {
				organizationId: this.authUserData.organizationId,
				month: this.date.substring(5, 7),
				year: this.date.substring(0, 4),
			};
			await this.$store.dispatch('billings/generateforallorganizationpatientsformonth', dataForBillingOrganizationAdmin);
			await this.initialize(dataForBillingOrganizationAdmin);
		},

		async initialize (object) {
			if (this.roleName === 'ORGANIZATION_ADMIN') {
				const headers = {
					organizationId: this.authUserData.organizationId,
					month: object.month,
					year: object.year,
				};
				await this.$store.dispatch('billings/getbillingsummaryforallpatientsformonth', headers).then(() => {
					var patientNumber = this.billingsFromRepo.length;

					this.billingSummary.cpt99453 = 0;
					this.billingSummary.cpt99454 = 0;
					this.billingSummary.cpt99457 = 0;
					this.billingSummary.cpt994581st = 0;
					this.billingSummary.cpt994582nd = 0;

					if (patientNumber > 0) {
						this.billingsFromRepo.forEach((patient, index) => {
							if (patient.cptCode99453?.status === 1) {
								this.billingSummary.cpt99453++;
							}
							if (patient.cptCode99454?.status === 1) {
								this.billingSummary.cpt99454++;
							}
							if (patient.cptCode99457?.status === 1) {
								this.billingSummary.cpt99457++;
							}
							if (patient.cptCode99458First?.status === 1) {
								this.billingSummary.cpt994581st++;
							}
							if (patient.cptCode99458Second?.status === 1) {
								this.billingSummary.cpt994582nd++;
							}

							let percent99454 = 0;
							let totalMeasurements99454 = 0;
							let measurement99454IsBillable = false;
							let measurement99457IsBillable = false;
							let measurement994581stIsBillable = false;
							let measurement994582ndIsBillable = false;
							if (patient.cptCode99454 !== null) {
								if (patient.cptCode99454.totalMeasurements < 16) {
									percent99454 = ((patient.cptCode99454.totalMeasurements / 16) * 100).toFixed(0);
									totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
								} else {
									totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
									percent99454 = 100;
									measurement99454IsBillable = true;
								}
							}

							const totalSec = patient.cptCode99457?.totalIntervalInSeconds + patient.cptCode99458First?.totalIntervalInSeconds + patient.cptCode99458Second?.totalIntervalInSeconds;

							let percent99457 = 0;
							let percent994581st = 0;
							let percent994582nd = 0;

							let seconds99457 = 0;
							let seconds994581st = 0;
							let seconds994582nd = 0;

							if (totalSec < 1200) {
								seconds99457 = totalSec;
								percent99457 = (((totalSec / 1200) * 100) < 100) ? ((totalSec / 1200) * 100).toFixed(1) : 100;

								seconds994581st = 0;
								percent994581st = 0;

								seconds994582nd = 0;
								percent994582nd = 0;
							}

							if (totalSec >= 1200 && totalSec < 2400) {
								seconds99457 = 1200;
								percent99457 = 100;
								measurement99457IsBillable = true;

								seconds994581st = totalSec - 1200;
								percent994581st = (((seconds994581st / 1200) * 100) < 100) ? ((seconds994581st / 1200) * 100).toFixed(0) : 100;

								seconds994582nd = 0;
								percent994582nd = 0;
							}

							if (totalSec >= 2400 && totalSec < 3600) {
								seconds99457 = 1200;
								percent99457 = 100;

								seconds994581st = 1200;
								percent994581st = 100;
								measurement994581stIsBillable = true;

								seconds994582nd = totalSec - 2400;
								percent994582nd = (((seconds994582nd / 1200) * 100) < 100) ? ((seconds994582nd / 1200) * 100).toFixed(0) : 100;
							}

							if (totalSec >= 3600) {
								seconds99457 = 1200;
								percent99457 = 100;

								seconds994581st = 1200;
								percent994581st = 100;

								seconds994582nd = totalSec - 2400;
								percent994582nd = 100;
								measurement994582ndIsBillable = true;
							}

							const myBillings = {
								totalMeasurements99454: totalMeasurements99454,
								percent99454: percent99454,
								percent99457: percent99457,
								percent994581st: percent994581st,
								percent994582nd: percent994582nd,
								seconds99457: seconds99457,
								seconds994581st: seconds994581st,
								seconds994582nd: seconds994582nd,
								m99454IsBillable: measurement99454IsBillable,
								m99457IsBillable: measurement99457IsBillable,
								m994581stIsBillable: measurement994581stIsBillable,
								m994582ndIsBillable: measurement994582ndIsBillable,
							};
							patient.myBillings = myBillings;
						});
						this.billingSummary.cpt99453 = ((this.billingSummary.cpt99453 / patientNumber) * 100).toFixed(0);
						this.billingSummary.cpt99454 = ((this.billingSummary.cpt99454 / patientNumber) * 100).toFixed(0);
						this.billingSummary.cpt99457 = ((this.billingSummary.cpt99457 / patientNumber) * 100).toFixed(0);
						this.billingSummary.cpt994581st = ((this.billingSummary.cpt994581st / patientNumber) * 100).toFixed(0);
						this.billingSummary.cpt994582nd = ((this.billingSummary.cpt994582nd / patientNumber) * 100).toFixed(0);
					}
				});
				this.aggregateData();
				this.closeAll();
			}

			if (this.roleName === 'DOCTOR' || this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'NURSE') {
				const headers = {
					hospitalId: this.loggedUser?.hospitalAssignedData?.id,
					month: object.month,
					year: object.year,
				};
				await this.$store.dispatch('billings/getbillingsummaryforallpatientsformonth', headers).then(() => {
					var patientNumber = this.billingsFromRepo.length;
					this.billingSummary.cpt99453 = 0;
					this.billingSummary.cpt99454 = 0;
					this.billingSummary.cpt99457 = 0;
					this.billingSummary.cpt994581st = 0;
					this.billingSummary.cpt994582nd = 0;
					if (patientNumber > 0) {
						this.billingsFromRepo.forEach((patient, index) => {
							if (patient.cptCode99453?.status === 1) {
								this.billingSummary.cpt99453++;
							}
							if (patient.cptCode99454?.status === 1) {
								this.billingSummary.cpt99454++;
							}
							if (patient.cptCode99457?.status === 1) {
								this.billingSummary.cpt99457++;
							}
							if (patient.cptCode99458First?.status === 1) {
								this.billingSummary.cpt994581st++;
							}
							if (patient.cptCode99458Second?.status === 1) {
								this.billingSummary.cpt994582nd++;
							}

							let percent99454 = 0;
							let totalMeasurements99454 = 0;
							let measurement99454IsBillable = false;
							let measurement99457IsBillable = false;
							let measurement994581stIsBillable = false;
							let measurement994582ndIsBillable = false;
							if (patient.cptCode99454 !== null) {
								if (patient.cptCode99454.totalMeasurements < 16) {
									percent99454 = ((patient.cptCode99454.totalMeasurements / 16) * 100).toFixed(0);
									totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
								} else {
									totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
									percent99454 = 100;
									measurement99454IsBillable = true;
								}
							}

							const totalSec = patient.cptCode99457?.totalIntervalInSeconds + patient.cptCode99458First?.totalIntervalInSeconds + patient.cptCode99458Second?.totalIntervalInSeconds;

							let percent99457 = 0;
							let percent994581st = 0;
							let percent994582nd = 0;

							let seconds99457 = 0;
							let seconds994581st = 0;
							let seconds994582nd = 0;

							if (totalSec < 1200) {
								seconds99457 = totalSec;
								percent99457 = (((totalSec / 1200) * 100) < 100) ? ((totalSec / 1200) * 100).toFixed(1) : 100;

								seconds994581st = 0;
								percent994581st = 0;

								seconds994582nd = 0;
								percent994582nd = 0;
							}

							if (totalSec >= 1200 && totalSec < 2400) {
								seconds99457 = 1200;
								percent99457 = 100;
								measurement99457IsBillable = true;

								seconds994581st = totalSec - 1200;
								percent994581st = (((seconds994581st / 1200) * 100) < 100) ? ((seconds994581st / 1200) * 100).toFixed(0) : 100;

								seconds994582nd = 0;
								percent994582nd = 0;
							}

							if (totalSec >= 2400 && totalSec < 3600) {
								seconds99457 = 1200;
								percent99457 = 100;

								seconds994581st = 1200;
								percent994581st = 100;
								measurement994581stIsBillable = true;

								seconds994582nd = totalSec - 2400;
								percent994582nd = (((seconds994582nd / 1200) * 100) < 100) ? ((seconds994582nd / 1200) * 100).toFixed(0) : 100;
							}

							if (totalSec >= 3600) {
								seconds99457 = 1200;
								percent99457 = 100;

								seconds994581st = 1200;
								percent994581st = 100;

								seconds994582nd = totalSec - 2400;
								percent994582nd = 100;
								measurement994582ndIsBillable = true;
							}

							const myBillings = {
								totalMeasurements99454: totalMeasurements99454,
								percent99454: percent99454,
								percent99457: percent99457,
								percent994581st: percent994581st,
								percent994582nd: percent994582nd,
								seconds99457: seconds99457,
								seconds994581st: seconds994581st,
								seconds994582nd: seconds994582nd,
								m99454IsBillable: measurement99454IsBillable,
								m99457IsBillable: measurement99457IsBillable,
								m994581stIsBillable: measurement994581stIsBillable,
								m994582ndIsBillable: measurement994582ndIsBillable,
							};
							patient.myBillings = myBillings;
						});
						this.billingSummary.cpt99453 = ((this.billingSummary.cpt99453 / patientNumber) * 100).toFixed(0);
						this.billingSummary.cpt99454 = ((this.billingSummary.cpt99454 / patientNumber) * 100).toFixed(0);
						this.billingSummary.cpt99457 = ((this.billingSummary.cpt99457 / patientNumber) * 100).toFixed(0);
						this.billingSummary.cpt994581st = ((this.billingSummary.cpt994581st / patientNumber) * 100).toFixed(0);
						this.billingSummary.cpt994582nd = ((this.billingSummary.cpt994582nd / patientNumber) * 100).toFixed(0);
					}
				});
				this.aggregateData();

				this.closeAll();
				// const billings = [];
			}
		},

		aggregateData () {
			const aggregatedData = {};

			// Iterate through the data
			for (const entry of this.billingsFromRepo) {
				const hospitalId = entry.hospitalData.id;

				if (!(hospitalId in aggregatedData)) {
					aggregatedData[hospitalId] = { counter: 0, counter99453: 0, percent99453: 0.0, counter99454: 0, percent99454: 0.0, counter99457: 0, percent99457: 0.0, counter994581st: 0, percent994581st: 0.0, counter994582nd: 0, percent994582nd: 0.0 };
				}

				aggregatedData[hospitalId].counter += 1;

				if (entry.cptCode99453?.status === 1) {
					aggregatedData[hospitalId].counter99453 += 1;
				}
				if (entry.cptCode99454?.status === 1) {
					aggregatedData[hospitalId].counter99454 += 1;
				}
				if (entry.cptCode99457?.status === 1) {
					aggregatedData[hospitalId].counter99457 += 1;
				}
				if (entry.cptCode99458First?.status === 1) {
					aggregatedData[hospitalId].counter994581st += 1;
				}
				if (entry.cptCode99458Second?.status === 1) {
					aggregatedData[hospitalId].counter994582nd += 1;
				}
			}

			// Calculate percentages after the loop
			for (const hospitalId in aggregatedData) {
				const totalCounter = aggregatedData[hospitalId].counter;
				if (totalCounter > 0) {
					aggregatedData[hospitalId].percent99453 = ((aggregatedData[hospitalId].counter99453 / totalCounter) * 100).toFixed(1);
					aggregatedData[hospitalId].percent99454 = ((aggregatedData[hospitalId].counter99454 / totalCounter) * 100).toFixed(1);
					aggregatedData[hospitalId].percent99457 = ((aggregatedData[hospitalId].counter99457 / totalCounter) * 100).toFixed(1);
					aggregatedData[hospitalId].percent994581st = ((aggregatedData[hospitalId].counter994581st / totalCounter) * 100).toFixed(1);
					aggregatedData[hospitalId].percent994582nd = ((aggregatedData[hospitalId].counter994582nd / totalCounter) * 100).toFixed(1);
				}
			}
			this.hospitalData = aggregatedData;
		},

		async expand (item) {
			if (item === this.expanded[0]) this.expanded = [];
			else this.expanded = [item];
		},
	},
};
</script>
